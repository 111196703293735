.fullpage{
      .login{
        height: 50vh;
        display: block;
        align-items: center;
        justify-content: center;
        margin-top: 35%;
        .Login-Logo{
          margin-bottom: 5%;
          width: 40%;
        }
        .language-select-div{
          display: flex;
          justify-content: right;
          margin-right: 10%;
          select{
            background-color: transparent;
            font-size: 1.2em;
            border: none;
          }
        }
        form{
          display: flex;
          flex-direction: column;
          align-items: center;

          input{
            width: 80%;
            height: 48px;
            margin: 15px;
            border-radius: 6px;
          }

          button{
            width: 80%;
            height: 48px;
            border-radius: 8px;
            border: none;
            background-color: #345b49;
            color: white;
            font-weight: bold;
            cursor: pointer;
          }

          span{
            font-size: 1.2em;
            font-weight: bold ;
            color: rgb(186, 13, 13);
            margin-top: 10px;
          }
        }


    }
    .contact{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
      a{
        color: #036208;
      }
        .contact-icon{
          font-size: 2em;
        
      }
    }
    .modal{
      display: flex;
      width: 90%;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin: auto;
      .modal-content{
        padding: 5%;

        input{
          width: 100% !important;
        }
      }
    }
    .forgetButton{
      width: 100% !important;
      border: none !important;
      color: black !important;
      background-color: transparent !important;
    }
    .forgetButton:focus{
      outline: none;
      color: rgb(87, 87, 87);
    }
}


@media (min-width: 767px) {
  .fullpage{
    .login{
      height: 50vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10%;
      .Login-Logo{
        margin-bottom: 1%;
        width: 25%;
      }
      form{
        display: flex;
        flex-direction: column;
        align-items: center;

        input{
          width: 30% !important;
          height: 48px;
          margin: 6px;
          border-radius: 6px;
        }

        button{
          width: 30% !important;
          height: 48px;
          border-radius: 8px;
          border: none;
          background-color: #036208;
          color: white;
          margin-top: 6px;
          font-weight: bold;
          cursor: pointer;
        }

        span{
          font-size: 1.2em;
          font-weight: bold ;
          color: rgb(186, 13, 13);
          margin-top: 10px;
        }
      }

    }
    .contact{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
      a{
        color: #036208;
      }
        .contact-icon{
          font-size: 2em;
        
      }
    }
    .modal{
      display: flex;
      width: 40% ;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin: auto;
      .modal-content{
        padding: 5%;

        input{
          width: 100% !important;
        }
      }
    }
    .forgetButton{
      width: 100% !important;
      border: none !important;
      color: black !important;
      background-color: transparent !important;
    }
    .forgetButton:focus{
      outline: none;
      color: rgb(87, 87, 87);
    }
  }
}