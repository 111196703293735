body{
    background-color: #fff;
}

.updatepassword{
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .cardStyle {
        width: 750px;
        border-color: white;
        background: #fff;
        padding: 36px 0;
        border-radius: 4px;
        margin: 30px 0;
        box-shadow: 0px 0 2px 0 rgba(0,0,0,0.25);
      }
      .formTitle{
        font-weight: 600;
        margin-top: 20px;
        color: #2F2D3B;
        text-align: center;
      }
      .inputLabel {
        font-size: 12px;
        color: #555;
        margin-bottom: 6px;
        margin-top: 24px;
      }
      .inputDiv {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin: auto;
      }
      input {
        height: 40px;
        font-size: 16px;
        border-radius: 4px;
        border: none;
        border: solid 1px #ccc;
        padding: 0 11px;
      }
      input:disabled {
        cursor: not-allowed;
        border: solid 1px #eee;
      }
      .buttonWrapper {
        margin-top: 40px;
      }
        .submitButton {
          width: 70%;
          height: 40px;
          margin: auto;
          display: block;
          color: #fff;
          background-color: #036208;
          border-color: #036208;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
          border-radius: 20px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
      .submitButton:disabled,
      button[disabled] {
        border: 1px solid #cccccc;
        background-color: #cccccc;
        color: #666666;
      }
      .updatedinformation{

            display: flex;
            justify-content: center;
            margin-top: 5%;
            font-weight: bold;
            color: #036208;
      }
      



}