.swiper-container{
    margin: 10% auto;
}

.swiper{
    width: 80% !important;
    margin-bottom: 5%;
}
.swiper-header{
    margin: auto;
    font-size: 2.25em;
    font-weight: bold;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}
.swiper-small-header{
    margin:2%  auto 0 auto;
    width: 80%;
    font-size: 1.25em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    color: gray;

}
.swiper-slide{

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: gainsboro;
    border: 1px solid rgb(195, 195, 195);
    border-radius: 48px;
    margin-top: 2%;
    width: 80%;
    .swipe-container{
        display: block;
        height: 17em !important;
        padding: 5%;
        margin-bottom: 5%;
        .icon{
            display: flex;
            background-color: #345b49;
            border-radius: 999px;
            width: 64px;
            height: 64px;
            margin: auto;
            justify-content: center;
            align-items: center;
            color: white;
            margin-bottom: 5%;

            svg{
                width:32px;
            }
        }
        .header{
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: rgb(94, 93, 93);
        }
        .content{
            display: flex;
            justify-content: center;
            align-items: center;
            
            p{
                padding-top: 1%;
                font-size: 1em;
                text-align:center;
                font-weight: 600;
                

            }
        }
    }
}

@media (max-width: 767px) {
    .swiper{
        width: 90% !important;
    }
    .swiper-container{
        margin: 20% 0 ;
    }
    .swiper-header{
        width: 95%;
        font-size: 1.8em;
    }
}