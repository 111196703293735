body{
  background-color: white;
}
.info-message{
  display: flex;
  justify-content: center;
  
}
.updateUser {
  width: 100%;
  display: flex;

  .updateUserContainer {
    flex: 6;
    background-color: white;
    
/* Sekme Başlıkları Stillemesi */
.tabHeaders {
  display: flex;
  overflow-x: auto;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
  white-space: nowrap;
  place-content: center;

}
.formRow {
  display: flex;
  justify-content: space-between; /* İçerik arasında boşluk */
  margin-bottom: 15px; /* Her satır arasında boşluk */

  .color-picker-input {
    width: 40px;
    height: 40px;
    border: none;
    background-color: #fff0;
    padding: 0;
    cursor: pointer;
    margin-left: 5%;
  }
  .upload-photo{
    display: flex;
    margin: auto;
    width: 100%;
  }
  img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    align-self: center;

  }
  .imgformInput{
    width: 100%;
  }
  .imgformLabel{
    align-self: center;

  }
  a{
    display: flex;
    justify-content: center;
    color: #890022;
    text-decoration: none;

  }
  a:hover{
    color: #f92f60;
    text-decoration: none;

  }
}
.form-button-container{
  display: flex;
  justify-content: center;
  button {
    display: block;
    width: 150px;
    padding: 10px;
    margin-bottom: 5%;
    border: none;
    background-color: #345b49;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 20px;

    &:disabled{
      background-color: rgba(3, 92, 92, 0.322);
      cursor: not-allowed;
    }}
}
.tabHeaders button {
  flex: 0 0 auto;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  margin-right: 10px;
}

.tabHeaders button:hover {
  background-color: #f0f0f0;
}

.tabHeaders button.active {
  border-bottom: 3px solid #007bff;
  color: #007bff;
}

/* Sekme İçerikleri Stillemesi */
.tabContainer {
  padding: 20px;
  border-top: none;
  width: 90%;
  margin: auto;
}


.formInput {
  margin-bottom: 15px;
  display: flex;
  width: 48%;
  flex-direction: column; /* Mobil cihazlarda varsayılan olarak sütun düzeni */
  select{
            
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 2.5em;
    background-color: transparent;

  }
}

.formInput label {
  margin-bottom: 5px;
  font-weight: bold;
}

.formInput input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Mobil Cihazlar için Ek Stillemeler */
@media (max-width: 768px) {
  .tabHeaders{
    place-content: baseline ;

  }
  .tabHeaders button {
    padding: 10px;
    margin-top: 8%;
  }
  .formRow{
    display: block;
  }
  .formInput {

    display: block;
    width: 100%;

    input{
      width: 100%;
    }
  }
.tabContainer{
  width: 100%;
}

}

.inputContainer {
  position: relative;
}

.inputUsernameIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Geçerli ve Geçersiz Giriş Stillemeleri */
input.valid {
  border-color: #28a745;
}

input.invalid {
  border-color: #dc3545;
}
    .top{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 10px;
      margin: 20px auto 20px auto;
      display: flex;
      width: 95%;
      justify-content: center;

      h1 {
        color: black;
        font-size: 20px;
      }
      .right {
        flex: 2;
        .img-container{
          display: flex;
          text-align: center;
          .child-img-container{
            width: 50%;
            text-align: -webkit-left;
            margin-left: 5%;
          }
          .img-label{
            display: block;
            font-size: 0.75em;
            font-weight: bold;
          }
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        form {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          justify-content: space-around;
          .upload-photo{
            display: flex;
            justify-content: space-between;
            width: 100%;
            .formInput {
              display: flex;
              width: 50%;
              align-items: center;
              justify-content: center;
              margin-left: 5%;
              justify-content: left;
  
              label {
                display: flex;
                align-items: center;
                gap: 0;
                font-weight: 800;
                color: black;
  
                .icon {
                  cursor: pointer;
                }
                select{
            
                  width: 100%;
                  border: none;
                  border-bottom: 1px solid gray;
                  height: 52%;
                  background-color: transparent;
      
                }
              }
  
              input {
                width: 100%;
                padding: 5px;
                border: none;
                border-bottom: 1px solid gray;
                background-color: transparent;

              }
            }
          }
          .form-subTitle{
            display: block;
            width: 100%;
            font-size: 1.5em;
            font-weight: bold;
          }
          .colorInput{
            width: 40% !important;            
          }
          .formInput {
            width: 40%;

            .color-picker-input {
              width: 40px;
              height: 40px;
              border: none;
              background-color: #fff0;
              padding: 0;
              cursor: pointer;
              margin-left: 5%;
            }
            label {
              display: flex;
              align-items: center;
              gap: 10px;
              font-weight: 800;
              color: black;

              .icon {
                cursor: pointer;
              }
            }
            select{
            
              width: 100%;
              border: none;
              border-bottom: 1px solid gray;
              height: 52%;
  
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
              background-color: transparent;

            }
          }
          .form-button-container{
            width: 50%;
            display: flex;
            justify-content: center;
            button {
              display: block;
              width: 150px;
              padding: 10px;
              border: none;
              background-color: #345b49;
              color: white;
              font-weight: bold;
              cursor: pointer;
              margin-top: 10px;
              border-radius: 20px;
  
              &:disabled{
                background-color: rgba(3, 92, 92, 0.322);
                cursor: not-allowed;
              }
            }
            
          }

        }
      }
    }
  }
}
.inputUsernameContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.inputUsernameContainer input {
  width: 100%;
  padding-right: 30px; /* İkon için yer bırak */
}

.inputUsernameIcon {
  position: absolute;
  right: 10px; /* Sağ kenardan 10px uzaklık */
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .updateUserContainer {
    width: 100%;
    padding: 0;

    .top{
      margin: 50px auto 20px auto !important;
    }
    .top,.bottom{
      width: 95%;
    } 
    .bottom {
      margin: 10px auto;
      width: 90%;
    }

    .right {
      .img-container {
        margin-bottom: 20px;
      }
      .img-container{
        display: flex;
        text-align: center;
        justify-content: center;
        .child-img-container{
          width: 60% !important;
          text-align: -webkit-left;
          margin-left: 0 !important;

        }
        .img-label{
          display: block;
          font-size: 0.75em;
          font-weight: bold;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
        }
        a{
          display: flex;
          justify-content: center;
          color: #f92f60;
        }
        a:hover{
          color: #c80637;

        }
      }
      .colorInput{
        width: 40% !important;
        display: block !important;

        
      }
      .color-picker-input {
        margin: 0 !important;
      }
      form {
        .upload-photo{
          display: flex;
          justify-content: center;
          width: 100%;

          .formInput {
            display: flex;
            width: 40%;
            align-items: center;
            justify-content: center;
            margin-left: 0 !important;
            justify-content: center !important;

            label {
              display: flex;
              align-items: center;
              gap: 0;
              font-weight: 800;
              color: black;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
              background-color: transparent;
            }
          }
        }
        .form-subTitle{
          display: block;
          width: 100%;
          font-size: 1.5em;
          font-weight: bold;
          margin-left: 0 !important;
        }
        .formInput {
          width:  100% !important;

          label {
            font-size: 16px;
          }

          input {
            font-size: 16px;
            background-color: transparent;
          }
          select{
            
            width: 100%;
            border: none;
            border-bottom: 1px solid gray;
            height: 52%;

          }
        }
        .form-button-container {
          button {
            width: 100%;
            font-size: 18px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  
  }
}
