.fullpage{
    .login{
      height: 50vh;
      display: block;
      align-items: center;
      justify-content: center;
      margin-top: 35%;


      form{
        display: flex;
        flex-direction: column;
        align-items: center;

        input{
          width: 80%;
          height: 48px;
          margin: 15px;
          border-radius: 6px;
        }

        button{
          width: 80%;
          height: 48px;
          border-radius: 8px;
          border: none;
          background-color: #345b49;
          color: white;
          font-weight: bold;
          cursor: pointer;
        }

        span{
          font-size: 1.2em;
          font-weight: bold ;
          color: rgb(186, 13, 13);
          margin-top: 10px;
        }
      }

      button{
        width: 80%;
        height: 48px;
        border-radius: 8px;
        border: none;
        background-color: #345b49;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
  }

}


@media (min-width: 767px) {
.fullpage{
  .login{
    height: 50vh;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
    .Login-Logo{
      margin-bottom: 1%;
      width: 25%;
    }
    form{
      display: flex;
      flex-direction: column;
      align-items: center;

      input{
        width: 30% !important;
        height: 48px;
        margin: 6px;
        border-radius: 6px;
      }

      button{
        width: 30% !important;
        height: 48px;
        border-radius: 8px;
        border: none;
        background-color: #036208;
        color: white;
        margin-top: 6px;
        font-weight: bold;
        cursor: pointer;
      }

      span{
        font-size: 1.2em;
        font-weight: bold ;
        color: rgb(186, 13, 13);
        margin-top: 10px;
      }
    }
    .newButton{
        display: flex;
        width: 30%;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 5%;
        background-color: #036208;

    }
  }

}
}