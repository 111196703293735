.Midcontainer{
    display: flex;
    padding: 2% 10%;
    background: linear-gradient(90deg, rgba(44,71,44,1) 0%, rgba(58,110,74,1) 53%, rgba(60,113,78,1) 100%);
    color: white;
    .left{
        display: flex;
        width: 60%;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
        padding-right: 15%;
        .content-container{
            h1{
                font-weight: bold;
            }
            p{
                display: flex;
                text-align: justify;
                font-size: 1.1em;
                font-weight: 400;
            }
            button{
                width: 100%;
                height: 48px;
                border: none;
                font-weight: bold;
                color: #3a6e4a;
                background-color: white;
                border-radius: 64px;
                cursor: pointer;
                align-items: center;
            }
        }
    }
    .right-mobile{
        display: none;
    }
    .right{
        display: flex;
        margin: 5% auto;
        width: 40%;
        justify-content: center;
        align-items: center;
        img{
            width: 60%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 480px){
    .Midcontainer{
        margin: auto;
        display: block;
        padding: 5%;


        .right-mobile{
            display: block;
            margin: 5% auto;
            width: 60%;
            justify-content: center;
            align-items: center;
            img{
                width: 100%;
                height: 100%;
                margin: 5%;
            }
        }
        .right{
            display: none;
        }
        .left{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin: auto !important;
            padding: 5%;
            .content-container{
                h1{
                    font-weight: bold;
                    text-align: center;

                }
                p{
                    display: flex;
                    text-align: justify;
                    font-size: 1.1em;
                    font-weight: 400;

                }
                button{
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    width: 100%;
                    height: 48px;
                    border: none;
                    font-weight: bold;
                    color: #3a6e4a;
                    background-color: white;
                    border-radius: 64px;
                    cursor: pointer;
                    align-items: center;
                }
            }
        }
    }
}