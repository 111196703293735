.profile {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    .profileContainer {
      flex: 6;
  
      .top {
        padding: 20px;
        gap: 20px;
        justify-content: center;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        background-color: #343434;
        .logo{
            display: flex;
            justify-content: left;
            position: absolute;
            width: 25%;
            margin-left: 0;

            .logoImg{
              width: 100%;
              height: 100%;
            }
        }
        .item {
            display: flex;
            gap: 20px;
            justify-content: center;
  
            .itemImg {
                margin-top: 10%;
              width: 150px;
              height: 150px;
              border-radius: 50%;
              object-fit: cover;
            }
        }

        .profile-info{

          margin-top: 5%;
          margin-bottom: 5%;
          .title {
            font-size: 1em;
            color: #e3e7e5;
            margin-bottom: 6px;
            font-weight: 400;
          }
          .company {
            font-size: 1.6em;
            color: #e3e7e5;
            margin-bottom: 2px;
            font-weight: bold;
          }
          .job{
            font-size: 1em;
            color: #e3e7e5;
            margin-bottom: 2px;
            font-weight: 400;
          }
        }

        .social{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 75%;
            margin: auto;
            gap: 12px;

            button{
                width: 2em;
                height: 2em;
                background-color: #F8F8F8;
                border-radius: 8px;
                padding: 0;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 1.5em;
                    font-weight: 900;
                    color: #343434;
                    pointer-events: none;
                }
                .marketplace{
                  width: 36px;
                  height: 36px;
                }

            }
            .copy{
              font-size: 3.5em;
              font-weight: 900;
              color: #343434;
              pointer-events: none;
              margin-left: auto;
              margin-bottom: 6%;
            }

        }
      }
      .copy{
        margin-left: auto;
        font-size: 1.5em;
        font-weight: 900;
        color: #F8F8F8;
        pointer-events: none
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;   
        padding: 0;
        margin: 10px;

        .buttons-container{
          display: block;
          align-items: center;
          justify-content: center;
          width: 100%;
          
          .bottom-button{
            display: flex;
            width: 100%;
            padding: 10px 20px;
            justify-content: left;
            align-items: center;
            border: none;
            background-color: #343434;
            margin: 10px 0;
            border-radius: 25px;
            cursor: pointer;
            color: black;
            a{
              text-decoration: none;
            }
            .text{
              font-size: 1em;
              font-weight: bold;
              margin-left: 5%;
              color: #F8F8F8;
              pointer-events: none;
              text-align: left;
              margin-top: auto;
              margin-bottom: auto;
            }
          
            .icon{
              pointer-events: none;
              font-size: 2em;
              color: #F8F8F8;
            }
            .marketplace{
              width: 36px;
              height: 36px;
            }

          }
        }
      }
      .template-box-container{
        margin-top: 4% !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: auto;
        gap: 8px !important;
        img{
          pointer-events: none;
        }
        .icon{
          pointer-events: none;
          
        }
        .boxTextContainer{
          width: 100%;
          display: contents;
          .boxText{
            font-size: 0;
          }
        }

      }
      .template-box{
        cursor: pointer;
        border: none;
        width: 30%;
        background-color: #343434;
        border-radius: 12px;
        height: 5em;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
          width: 2.5em !important;
          height: 2.5em !important;
        }
      }
      .marketplace{
        width: 56px;
        height: 56px;
      }

    }
    .text-container{
      width: 100%;
      #bankName{
        font-size: 0.9em;
      }
      #bankIban{
        font-size: 0.9em;
        width: 100%;
      }
      #bankRecipient{
        font-size: 0.9em;
      }
    }
    .modal{
      display: inherit;

      .modal-header{
        font-size: 1.2em;
        font-weight: bold;
      }
      .modal-body{
        p{
          font-size: 1.2em;
          font-weight: 500;
        }
        .button-label{
          font-size: 1.2em;
          display: block;
          color: #343434;
        }
        button{
          cursor: pointer;
          border: none;
          border-radius: 8px;
          background-color: #164d90;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          margin: auto;
          width: 2.5em;
          height: 2.5em;
          .icon{
            font-size: 1.5em;
            font-weight: 900;
            color: #e3e7e5;
            pointer-events: none;

          }
          .marketplace{
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .language-select-div{
      display: flex;
      position: absolute;
      top: 6% ;
      left: 80% ;
      select {
        border: none;
        background-color: transparent;
        color: white;

        option{
          width: 100%;
          font-size: 0.75rem;
          color: white;
          background-color: #343434;
          border: none;

        }
      }

    }

  }


  /* For screens bigger than 768px */
@media (min-width: 767px) {

  .profile{
    width: 40%;
    justify-content: center;
    margin: auto;
  }
  .logo{
    width: 10% !important;
  }


  .language-select-div{
    display: flex;
    position: absolute;
    top: 7% !important;
    left: 64% !important;
  }

}
  
.loader-div{
  position: fixed; /* Sabit pozisyon, sayfa içeriği ne olursa olsun ekranın tamamını kaplar */
  top: 0;
  left: 0;
  width: 100vw; /* Viewport genişliğinin tamamını kaplar */
  height: 100vh; /* Viewport yüksekliğinin tamamını kaplar */
  background-color: #034C91; /* Yarı saydam siyah arka plan */
  display: flex;
  justify-content: center; /* Yatayda ortalar */
  align-items: center; /* Dikeyde ortalar */
  z-index: 9999;
}
.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}