// Panel.module.scss
body{
  background-color: #ffffff;
  
}
.panelContainer {
    display: flex;
    flex-wrap: wrap; // Ekran boyutuna göre içerikleri alt alta alabilir
    margin: auto; // Otomatik marjın ile ortala
    padding: 20px;
    width: 80%;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .panelItem {
    margin: 10px; // Her bir panel öğesine marjın ver
    flex-basis: calc(50% - 20px); // Her öğe için temel genişlik hesaplaması
    align-self: start; // İçerikleri üst kenara hizala
  }
  
  .panelTitle {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .chartContainer {
    width: 100%; // Grafik konteynerinin genişliği
    padding: 10px; // İç padding
    background: #ffffff; // Arka plan rengi
    border-radius: 8px; // Kenar yuvarlaklığı
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Gölge efekti
  }

  .pieChart{
    max-width: 600px;
    display: flex;
    margin: auto;
  }
  .tableContainer {
    margin-top: 20px;
  }
  
  .panelTitle {
    text-align: center;
    margin-bottom: 10px;
  }
  .pageVisits{
    text-align: center;

  }
  /* Tablo stil tanımlamaları */
  .table {
    width: 100%; /* Konteyner genişliğini kaplar */
    border-collapse: collapse; /* Kenarlık aralıklarını kaldırır */
    margin-top: 20px; /* Üstten boşluk */
  }
  
  .table td,
  .table th {
    border: 1px solid #ddd; /* Hücre kenarlıkları */
    padding: 8px; /* İç boşluk */
  }
  
  .table tr:nth-child(odd) {
    background-color: #4CAF50; /* Başlık arka plan rengi */
    color: white; /* Başlık yazı rengi */
  }
  
  .table tr:hover {
    background-color: #4CAF50; /* Başlık arka plan rengi */
    color: white; /* Başlık yazı rengi */  }
  
  .table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #ddd; /* Başlık arka plan rengi */
    color: black; /* Başlık yazı rengi */
    font-size: 24px;
  }

  .pageTitle{
    font-weight: bold;
    text-align: center;
    margin-top: 5%;
  }

  // Diğer stil tanımlamaları...
  @media (max-width: 767px) {

    .pageTitle{
      margin-top: 15%;
    }
    .panelContainer{
      width: 100%;
    }
    .panelItem{
      flex-basis: 100%;
    }
  }