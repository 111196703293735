@font-face {
  font-family: 'InlanderTexture';
  src: url('../../../fonts/InlanderTexture.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.Intro {
    display: flex;
    margin-top: -5em;
    .container{
      background-image: url("../../../img/forest.png");
      background-size: cover;
      max-width: 100%;
      height: 35vw;
      display: flex;
      justify-content: center;
      .border-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: transparent;
        margin: 8% 5% 5% 5%;
        border: 5px solid #ffffff;
        background: rgba(0,0,0,0.7);
        background: linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
        background: -webkit-linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
        background: -moz-linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);

        h1{
          display: block;
          font-family: 'InlanderTexture';
          color: white;
          font-size: 3em;
          text-align: center;

        }


      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 800px){
    .Intro {
      display: flex;
  
      .container{

        max-width: 100%;
        height: 75vw;
        .border-container{
          display: flex;
          align-items: center;
          width: 100%;
          background-color: transparent;
          margin: 8em 5% 10% 5%;
          border: 2px solid #ffffff;
          background: rgba(0,0,0,0.7);
          background: linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
          background: -webkit-linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
          background: -moz-linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
  
  
          h1{
            font-family: 'InlanderTexture';
            color: white;
            font-size: 2.5em;
            text-align: center;
  
          }
  
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .Intro {
      display: flex;
  
      .container{

        max-width: 100%;
        height: 75vw;
        .border-container{
          display: flex;
          align-items: center;
          width: 100%;
          background-color: transparent;
          margin: 8em 5% 10% 5%;
          border: 2px solid #ffffff;
          background: rgba(0,0,0,0.7);
          background: linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
          background: -webkit-linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
          background: -moz-linear-gradient(180deg,rgba(0,0,0,0.7) 0%, rgba(0,255,0,0) 80%);
  
  
          h1{
            font-family: 'InlanderTexture';
            color: white;
            font-size: 1.5em;
            text-align: center;
  
          }
  
        }
      }
    }
  }
  