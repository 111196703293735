.Homenavbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  align-content:center;
  font-size: 14px;

  color: #555;
  nav {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  }
  .logo{
    margin-left: 2%;
    img{
        width: 100%;
    }
    a {
        color: #fff;
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: bold;
      }
  }
  
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: max-height 0.5s ease-out;
    max-height: 0;
  }
  
  .menu.open {
    max-height: 500px;
  }
  
  .menu li {
    margin: 0 1rem;
  }
  
  .menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  .menu-icon {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
    z-index: 99999;
  }
  
  @media screen and (max-width: 768px) {
    .navbar{
    padding: 0 !important;

    }
    .logo{

        margin-left: 0 ;
        img{
            width: 50%;
        }
        a {
            color: #fff;
            text-decoration: none;
            font-size: 1.5rem;
            font-weight: bold;
          }
      }
    .menu {
      flex-direction: column;
      background-color: #2c4347e2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vw;
      overflow: hidden;
    }
  
    .menu li {
      margin: 1rem 0;
    }
  
    .menu-icon {
      display: block;
    }
  
    .menu.open {
      max-height: 500px;
      margin-top: -1px;
    }
  }
  
  .language-select-div{
    display: flex;
    position: inherit;
    left: auto !important;
    top: auto !important;
    
    select{
      background-color: transparent;
      color: white;
      font-size: 1.2em;
      border: none;
      border: none;
      option{
        color: white;
        background-color: #2C4347;
      }
    }
  }
  .logout{
    cursor: pointer;
  }

    .logo-container{
      display: flex;
      .logo{

        width: 50%;
        vertical-align: middle;
        border-style: none;
      }
    }
    .items {
      display: block;
      align-items: center;
      justify-content: right;
      margin-left: auto;
      width: 100%;
      margin-top: 5%;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        justify-content: center;
        margin-bottom: 4%;
        .icon {
          cursor: pointer;
          font-size: 20px;
        } 
        button{
          background-color: transparent;
          color:white;
          font-size: 1.2em;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          cursor: pointer;
        }
        button:hover{
          color: #2C4347;
        }
      }
    }
  
}
@media (min-width: 767px) {
  .Homenavbar {
    height: 75px;
    display: flex;
    align-items: center;
    align-content:center;
    font-size: 14px;
    color: #555;
    border: none;
    .logout{
      cursor: pointer;
    }

      .logo-container{
        display: flex;
        .logo{
  
          width: 35% !important;
          vertical-align: middle;
          border-style: none;
        }
      }
      .items {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-left: auto;
        width: 100%;
        .item {
          display: flex;
          align-items: center;
          position: relative;
  
          .icon {
            cursor: pointer;
            font-size: 1.2em;          
          } 
            button{
            background-color: transparent;
            font-size: 1.2em;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            cursor: pointer;
          }

        }
        button:hover{
          color: #036208;
        }
      }
    
  }
}